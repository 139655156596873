// Add your custom JS here.

import 'swiper/swiper-bundle.min.css';
import Swiper from 'swiper/bundle';


function main(){
    return new Swiper('.main-slide .swiper-container', {
        loop: true,
        effect: "fade",
        autoplay: {
                delay: 4000, // 4秒後に次のスライドへ
                disableOnInteraction: false, // ユーザーが操作しても自動再生を継続
            },
            speed: 2000, // 2秒かけてフェード
    });    
}
main();

function carouselSwiper(){
    return new Swiper('.section-carousel .swiper', {
        slidesPerView: 'auto',
        spaceBetween: 24,
        grabCursor: true,
        pagination: {
            el: '.carousel .swiper-pagination',
            clickable: true,
        },
        pagination: {
        el: ".swiper-pagination",
        clickable: true,
        },
        breakpoints: {
            1025: {
            spaceBetween: 32,
            }
        },
    });
}
carouselSwiper();

//サムネイル

function sliderThumbnail(){
    return new Swiper('.slider-thumbnail', {
        slideToClickedSlide: true,
        slidesPerView: 3, 
        spaceBetween: 10,
        breakpoints: {
            992: {
                slidesPerView: 1,
                spaceBetween: 0,
            }
        },
    });
}

function facilitySlider(){
    return new Swiper('.section-facility__slider .swiper', {
        effect: 'fade',
        loop: true,                         
            autoplay: {                         
                delay: 4000,  
            },
            speed: 1500, // 2秒かけてフェード
        thumbs: {
            swiper: sliderThumbnail(),
        },
    });
}
facilitySlider();

function achievement(){
    var xhr = new XMLHttpRequest();
    var ajaxUrl = '/wp-admin/admin-ajax.php';
    xhr.open("GET", ajaxUrl + "?action=archivement_json", true);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

    xhr.onreadystatechange = function() {
        if (xhr.readyState === 4 && xhr.status === 200) {
            var response = JSON.parse(xhr.responseText);
            console.log(response);

            const color = [
                '#78d176',
                '#00AA96',
                '#86B6FC',
                '#D2E0FB',
                '#A3ABBD',
            ];

            response.datasets.forEach(function(elm, index) {

                response.datasets[index].backgroundColor = color[index];
            });

            const config = {
                type:'bar',
                data:response,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            stacked: true,
                        }],
                        yAxes: [{
                            stacked: true
                        }]
                    },
                },

            };
            console.log(config);
            const myChart = new Chart(
                document.getElementById('myChart'),
                config
            );

        } else if (xhr.readyState === 4 && xhr.status !== 200) {
            console.error("Ajax request failed with status: " + xhr.status);
            alert('i');
        }
    };

    xhr.send();

}

function reloadPageOnTransition() {
    window.location.reload(true); // ページを再読み込み
}

import barba from '@barba/core';

function barbaEvent(data){
    if(data.next.url.path == "/about/achievement/"){
        achievement();
    }
    if(data.next.url.path == "/recruit/contact/"){
        
    }
    if(data.next.url.path == "/"){
        main();
        facilitySlider();
        carouselSwiper();
    }

}

barba.init({ 
    prevent: preventSettings,
    transitions: [{
        name: 'default-transition',
        async leave() {
            await new Promise(resolve => {
                document.getElementsByClassName('barba-wrap')[0].classList.add('fadeOut');
                window.scrollTo(0, 0);
                setTimeout(resolve, 800);
            });
            
        },
        async enter(data) {
            document.getElementsByClassName('barba-wrap')[0].classList.remove('fadeOut');
            barbaEvent(data);
        },
        
		async once(data) {
            barbaEvent(data);            
        },
    }]
});



function loadChartJS() {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/chart.js'; // chart.jsのCDNを指定する場合
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
}

function preventSettings(e){
    // 外部リンクはtarget="_blank"に
    let site_url = location.protocol + '//' + location.host;
    if (!e.href.startsWith(site_url)) {
        e.el.setAttribute('target','_blank');
        return true;
    }
    // アンカーリンクであり同一ページでなければbarbaを無効に
    let url = location.protocol + '//' + location.host + location.pathname;
    let extract_hash = e.href.replace(/#.*$/,"");
    if (e.href.startsWith(location.protocol + '//' + location.host)) {
        if (e.href.indexOf('#') > -1 && extract_hash != url ){
            return true;
        }
    }
    // 拡張子が該当する場合はtarget="_blank"に
    if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(e.href.toLowerCase())) {
        e.el.setAttribute('target','_blank');
        return true;
    }
    // 該当クラスに属していればBarbaを無効に
    let ignoreClasses = ['ab-item'];
    ignoreClasses.forEach((cls) => {
        if (e.el.classList.contains(cls)) {
            return true;
        }
    })

    if (e.el.classList.contains('ab-item')) {
        return true;
    }


}

/*
barba.hooks.beforeEnter((data) => {
    replaceHead(data);
})
*/
barba.hooks.after((data) => {
    if (typeof ga === 'function') {
        ga('set', 'page', window.location.pathname);
        ga('send', 'pageview');
    }
});
 

jQuery(function ($) {
    //メニュー開閉
    $(window).on('load resize', function(){
        var wid = $(window).width();
        if (wid <= 1200) {
            $('.footer__menu .menu-item-has-children').on('click', function (e) {
                $(this).toggleClass('active');
                $(this).children('ul').stop().slideToggle(500);
                e.stopPropagation();
            });
        } 
        if (wid <= 992){
            $('.header__main--sp .navbar-collapse .menu-item-has-children').on('click', function (e) {
                $(this).toggleClass('current');
                $(this).children('ul').stop().slideToggle(500);
                e.stopPropagation();
            });
        }
    });
    //ヘッダースクロール制御
    $(window).on("scroll", function () {
        const sliderHeight = $(".header-height").height();
        if (sliderHeight  < $(this).scrollTop()) {
            $(".js-header").addClass("headerScroll");
        } else { 
            $(".js-header").removeClass("headerScroll");
        }
    });

        // hoverする要素のclass名
    $(".header__main--pc .dropdown").hover(
        function () {
        $("#sub-menu .access").addClass("js-hover");
        $("#sub-menu .tel").addClass("js-hover");
        $(".header__main--pc .nav-link").addClass("js-hover");
        },
        function () { 
            
        $(".access").removeClass("js-hover");
        $("#sub-menu .tel").removeClass("js-hover");
        $(".header__main--pc .nav-link").removeClass("js-hover");
        }
    );

    var container = $('.chart_container');
    var ctx= $('#myChart');
    ctx.attr('width', container.width());
    ctx.attr('height', 400);

//タブ
$(window).on('load resize', function(){
        
        $( document ).on( 'click', '.areoi-tabs .nav a', function( e ) {
                e.preventDefault();
        
                var clickedTab = $(this);
                var targetPane = $(clickedTab.attr('href'));
        
                // .nav a から .active を削除
                clickedTab.closest('.nav').find('a').removeClass('active');
        
                // クリックされたタブに .active を追加
                clickedTab.addClass('active');
        
                // .tab-pane から .active を削除
                clickedTab.closest('.areoi-tabs').find('.tab-pane').removeClass('active');
        
                // 対応するタブペインに .active を追加
                targetPane.addClass('active');
        });
});

$('a').click(function() {
    var href = $(this).attr('href');
    
    // 正規表現を使用して、hrefが#で始まるかどうかを確認
    var isHashLink = /^#/.test(href);

    // hrefが#で始まる場合はスクロール処理を実行
    if (isHashLink) {
        var target = $(href === '#' ? 'html' : href);
        var position = target.offset().top - headerHeight;
        $('html, body').animate({ scrollTop: position }, 500);
        return false;
    }
});

});
